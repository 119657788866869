import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CookieConsent } from "react-cookie-consent";
import "./App.scss";
import "./scss/fonts.css";
import { Header } from "./Components/Header/Header";
import { BrowserRouter as Router } from "react-router-dom";
import { Sidebar } from "./Components/Sidebar";
import AnimatedRoutes from "./Components/AnimatedRoutes";
import { Footer } from "./Components/Footer/Footer";
import AppProvider from "./providers/AppProvider";
import { ApolloProvider } from "@apollo/client";
import client from "./api/apolloClient";
import { HelmetProvider } from "react-helmet-async";
import ScrollManager from "./Components/ScrollManager";
import Cookies from "./Components/Cookies";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <AppProvider>
          <HelmetProvider>
            <Router>
              <ScrollManager />
              <Header />
              <Sidebar />
              <main id="main" className={"main-content"}>
                <AnimatedRoutes />
                <Footer />
              </main>
              <Cookies />
            </Router>
          </HelmetProvider>
        </AppProvider>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

export default App;
