import BLOYART from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Bloyart.jpg";
import ALLEMEERSCH from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Allemeersch.jpg";
import BROSSE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Brosse.png";
import DEBONNE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Debonne.jpg";
import DEHERTOGH from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-DeHertogh.jpg";
import DERYCKE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-DeRycke.jpg";
import DESABLENS from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Desablens.jpg";
import DHAENE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Dhaene.jpg";
import ERGOPONCELET from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-ErgoPoncelet.jpg";
import FEYAERTS from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Feyaerts.jpg";
import FORRIER from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Forrier.jpg";
import FUNERAILLESBRAINOISES from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-FuneraillesBrainoises.png";
import GOORISVANCAMP from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-GoorisVanCamp.jpg";
import HAAST from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Haast.jpg";
import LECLERCQDELIENER from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-LeclercqDeliener.jpg";
import LENCHANT from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Lenchant.jpg";
import LITS from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Lits.jpg";
import LOGGHE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Logghe.jpg";
import LUCAS from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Lucas.jpg";
import MAHY from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Mahy.jpg";
import MELIS from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Melis.jpg";
import PLANCKAERT from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Planckaert.jpg";
import POLLIE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Pollie.jpg";
import POPELIER from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Popelier.jpg";
import QUIRIJNEN from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Quirijnen.jpg";
import RIGOLE from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Rigole.jpg";
import SERENI_ANTWERPEN from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Sereni-Antwerpen.jpg";
import SERVATY from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Servaty.jpg";
import STEVENS_LENCHANT from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-StevensLenchant.jpg";
import VAN_BOGAERT from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-VanBogaert.jpg";
import VERHOEVEN from "../assets/images/location-logos/sereni-vestiging-300dpi-cmyk-Verhoeven.jpg";
import BINCHOISES_ZWART from "../assets/images/location-logos/Vestigingslogo_Binchoises-Zwart.jpg";
import BOGAERT_ZWART from "../assets/images/location-logos/Vestigingslogo_Bogaert-Zwart.jpg";
import DAEMS_ZWART from "../assets/images/location-logos/Vestigingslogo_Daems_Zwart.jpg";
import DE_PIRAMIDE_ZWART from "../assets/images/location-logos/Vestigingslogo_De_Piramide_Zwart.jpg";
import DEBONNE_RIGOLE_ZWART from "../assets/images/location-logos/Vestigingslogo_DebonneRigole_Zwart.jpg";
import DE_CEDER_ZWART from "../assets/images/location-logos/Vestigingslogo_DeCeder_Zwart.jpg";
import DELVAL_ZWART from "../assets/images/location-logos/Vestigingslogo_Delval_Zwart.jpg";
import DEPOORTER_ZWART from "../assets/images/location-logos/Vestigingslogo_Depoorter_Zwart.jpg";
import DHONDT_BOCKELANDT_ZWART from "../assets/images/location-logos/Vestigingslogo_DhondtBockelandt_Zwart.jpg";
import FONTAINOISES_ZWART from "../assets/images/location-logos/Vestigingslogo_Fontainoises_Zwart.jpg";
import HAYEN_ZWART from "../assets/images/location-logos/Vestigingslogo_Hayen-Zwart.jpg";
import HONORE_DESCAMPS_ZWART from "../assets/images/location-logos/Vestigingslogo_Honore_Descamps_Zwart.jpg";
import JACQUEMIN_ZWART from "../assets/images/location-logos/Vestigingslogo_Jacquemin-Zwart.jpg";
import REWEQHS_ZWART from "../assets/images/location-logos/Vestigingslogo_Reweghs_Zwart.jpg";
import REWEQHS_PIPELEERS_ZWART from "../assets/images/location-logos/Vestigingslogo_Reweghs-Pipeleers_Zwart.jpg";
import SABBE_ZWART from "../assets/images/location-logos/Vestigingslogo_Sabbe_Zwart.jpg";
import SNOECK_ZWART from "../assets/images/location-logos/Vestigingslogo_Snoeck-Zwart.jpg";
import TIMMERMAN_ZWART from "../assets/images/location-logos/Vestigingslogo_Timmerman_Zwart.jpg";
import ZWART from "../assets/images/location-logos/Vestigingslogo_Zwart.jpg";
import VAN_KUYK_ZWART from "../assets/images/location-logos/Vestigingslogo-Van-Kuyk-Zwart.jpg";


export const LOGOS = {
  BLOYART,
  ALLEMEERSCH,
  BROSSE,
  DEBONNE,
  DEHERTOGH,
  DERYCKE,
  DESABLENS,
  DHAENE,
  ERGOPONCELET,
  FEYAERTS,
  FORRIER,
  FUNERAILLESBRAINOISES,
  GOORISVANCAMP, // ?
  HAAST,
  LECLERCQDELIENER,
  LENCHANT,
  LITS,
  LOGGHE,
  LUCAS,
  MAHY,
  MELIS,
  PLANCKAERT,
  POLLIE,
  POPELIER,
  QUIRIJNEN,
  RIGOLE,
  SERENI_ANTWERPEN,
  SERVATY,
  STEVENS_LENCHANT,
  VAN_BOGAERT,
  VERHOEVEN,
  BINCHOISES_ZWART,
  BOGAERT_ZWART,
  DAEMS_ZWART,
  DE_PIRAMIDE_ZWART,
  DEBONNE_RIGOLE_ZWART, //?
  DE_CEDER_ZWART, //?
  DELVAL_ZWART,
  DEPOORTER_ZWART,
  DHONDT_BOCKELANDT_ZWART, //?
  FONTAINOISES_ZWART,
  HAYEN_ZWART,
  HONORE_DESCAMPS_ZWART,
  JACQUEMIN_ZWART,
  REWEQHS_ZWART,
  REWEQHS_PIPELEERS_ZWART,
  SABBE_ZWART,
  SNOECK_ZWART,
  TIMMERMAN_ZWART,
  ZWART, //?
  VAN_KUYK_ZWART
};
