import ALLEMEERSCH from '../assets/images/location-images/Allemeerscjh.jpeg'
import SERENI_ANTWERPEN from '../assets/images/location-images/Antwerpen.jpeg'
import BLOYART from '../assets/images/location-images/Bloyaert.jpeg'
import DESABLENS from '../assets/images/location-images/Desablens.jpeg'
import DERYCKE from '../assets/images/location-images/De Rycke.jpeg'
import DEPOORTER from '../assets/images/location-images/De Poorter.jpeg'
import DELVAL from '../assets/images/location-images/Delval.jpeg'
import BINCHOISES from '../assets/images/location-images/Binchoises.png'
import BRAINOISES from '../assets/images/location-images/Brainoises.jpeg'
import VAN_BOGAERT from '../assets/images/location-images/Van Bogaert.jpeg'
import BOGAERT from '../assets/images/location-images/Bogaert.jpeg'
import DAEMS from '../assets/images/location-images/Daems.jpeg'
import HAAST from '../assets/images/location-images/Haast.jpeg'
import BRECHT from '../assets/images/location-images/Brecht.jpeg'
import CALS from '../assets/images/location-images/Cals.jpeg'
import DEPOLDER from '../assets/images/location-images/Depolder.jpeg'
import FONTAINOISES from '../assets/images/location-images/Fonainoises.jpeg'
import HAYE from '../assets/images/location-images/Haye.jpeg'
import HONORE_DESCAMPS from '../assets/images/location-images/Honore descamps.jpeg'
import JACQUEMIN from '../assets/images/location-images/Jacquemin.jpeg'
import LECLERCQ from '../assets/images/location-images/Leclerq.jpeg'
import LEIEKANT from '../assets/images/location-images/Leiekant.jpeg'
import LENCHANT from '../assets/images/location-images/Lenchant.jpeg'
import LOGGHE from '../assets/images/location-images/Logghe.png'
import LUCAS from '../assets/images/location-images/Lucas.jpeg'
import MANAGMENT from '../assets/images/location-images/Management.webp'
import MELIS from '../assets/images/location-images/Melis.jpeg'
import PLANCKAERT from '../assets/images/location-images/Planckaert.jpeg'
import QUIRIJNEN from '../assets/images/location-images/Quirijnen.jpg'
import REWEQHS from '../assets/images/location-images/Reweghs.jpeg'
import RIGOLE from '../assets/images/location-images/Rigole.jpeg'
import SERVATY from '../assets/images/location-images/Servaty.jpeg'
import STEVENS_LENCHANT from '../assets/images/location-images/Stevens & Lenchant.webp'
import TIMMERMAN from '../assets/images/location-images/Timmerman.jpeg'
import VAN_DAEL from '../assets/images/location-images/Van Dael.png'
import VAN_KUYK from '../assets/images/location-images/Van Kuyk.jpeg'
import VANDERLINDEN from '../assets/images/location-images/Vanderlinden.jpeg'
import VERHOEVEN from '../assets/images/location-images/Verhoeven 2.jpeg'

export const IMAGES = {
  BLOYART,
  ALLEMEERSCH,
  DERYCKE,
  DESABLENS,
  BRAINOISES,
  HAAST,
  LECLERCQ,
  LENCHANT,
  LOGGHE,
  LUCAS,
  MELIS,
  PLANCKAERT,
  QUIRIJNEN,
  RIGOLE,
  SERENI_ANTWERPEN,
  SERVATY,
  STEVENS_LENCHANT,
  VAN_BOGAERT,
  VERHOEVEN,
  BINCHOISES,
  BOGAERT,
  DAEMS,
  DELVAL,
  DEPOORTER,
  FONTAINOISES,
  HAYE,
  HONORE_DESCAMPS,
  JACQUEMIN,
  REWEQHS,
  TIMMERMAN,
  VAN_KUYK,
  DEPOLDER,
  BRECHT,
  VAN_DAEL,
  LEIEKANT,
  CALS,
  VANDERLINDEN,
  MANAGMENT
};
