export const PAGES = {
  HOME: "/",
  MENU: "/menu",
  BRANCH: "/branch",
  AFTERCARE: "/aftercare",
  CONTACT: "/contact",
  SUBSCRIBE: "/subscribe",
  EVENTS: "/events",
  EVENTS_FR: "/evenements",
  MY_SERENI: "http://mijn.sereni.be/",
  PARTNER: "/partner",
  ARTICLE: "/article",
  EVENT: "/event",
  JOBS: "/jobs",
  CONDOLEANCES: "/condoleances",
  COLLABORATIONS: "/samenwerkingen",
  COLLABORATIONS_FR: "/collaborations",
  INSPIRATIONS: "/inspiraties",
  INSPIRATIONS_FR: "/inspirations",
};
