import { IMAGES } from "../../utils/location-images";
import { LOGOS } from "../../utils/location-logos";

let listOfSereniLocations = [
  {
    id: 1,
    lat: 51.2948,
    lng: 4.489,
    src: IMAGES.QUIRIJNEN,
    title: "Quirijnen | Sereni",
    address: "Augustijnslei 85 - 2930 Brasschaat",
    hubMail: "noord.antwerpen@sereni.be",
    email: "quirijnen@sereni.be",
    website: "quirijnen.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 650 15 15",
    postalCode: "2930",
    url: "https://www.quirijnenuitvaart.be/nl/",
    logo: LOGOS.QUIRIJNEN,
  },
  {
    id: 2,
    lat: 51.3193,
    lng: 4.42586,
    src: IMAGES.HAAST,
    title: "Kapellen | Sereni",
    address: "Kapelsestraat 20 - 2950 Kapellen",
    hubMail: "noord.antwerpen@sereni.be",
    email: "kapellen@sereni.be",
    website: "kapellen.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 664 20 62",
    postalCode: "2950",
    url: "https://www.haastuitvaart.be",
    logo: LOGOS.HAAST
  },
  {
    id: 3,
    lat: 51.2303,
    lng: 4.41042,
    src: IMAGES.SERENI_ANTWERPEN,
    title: "Sereni | Antwerpen",
    address: "Napoleonkaai 51 - 2000 Antwerpen",
    hubMail: "noord.antwerpen@sereni.be",
    email: "antwerpen@sereni.be",
    website: "antwerpen.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 650 06 66",
    postalCode: "2000",
    url: "https://antwerpen.sereni.be/nl/",
    logo: LOGOS.SERENI_ANTWERPEN
  },
  {
    id: 4,
    lat: 51.3455,
    lng: 4.31923,
    src: IMAGES.DEPOLDER,
    title: "De Polder | Sereni",
    address: "Monnikenhofstraat 87 - 2040 Berendrecht",
    hubMail: "noord.antwerpen@sereni.be",
    email: "depolder.sereni.be",
    website: "depolder.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 568 63 37",
    postalCode: "2040",
    url: "https://www.depolderuitvaart.be/nl/"
  },
  {
    id: 5,
    lat: 51.37705,
    lng: 4.47422,
    src: IMAGES.BRECHT,
    title: "Sereni Kalmthout & Brecht",
    address: "Kapellensteenweg 287 - 2920 Kalmthout",
    hubMail: "noord.antwerpen@sereni.be",
    email: "brecht@sereni.be / kalmthout@sereni.be",
    website: "brecht.sereni.be / kalmthout.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 666 13 41",
    postalCode: "2920",
    url: "https://brecht.sereni.be/nl/"
  },
  {
    id: 6,
    lat: 51.25083,
    lng: 4.49623,
    src: IMAGES.DAEMS,
    title: "Daems | Sereni",
    address: "Churchilllaan 20/24 - 2900 Schoten",
    hubMail: "noord.antwerpen@sereni.be",
    email: "daems@sereni.be",
    website: "daems.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 658 45 61",
    postalCode: "2900",
    url: "https://www.daemsuitvaart.be/nl/",
    logo: LOGOS.DAEMS_ZWART
  },
  {
    id: 7,
    lat: 51.25071,
    lng: 4.43678,
    src: IMAGES.VAN_KUYK,
    title: "Van Kuyk | Sereni",
    address: "Nieuwdreef 210 - 2170 Merksem",
    hubMail: "noord.antwerpen@sereni.be",
    email: "vankuyk@sereni.be",
    website: "vankuyk.sereni.be",
    cluster: "HUB Noord Antwerpen",
    contactNo: "03 641 96 40",
    postalCode: "2170",
    url: "https://www.vankuykuitvaart.be/nl/",
    logo: LOGOS.VAN_KUYK_ZWART
  },
  {
    id: 8,
    lat: 51.08713,
    lng: 4.369524,
    src: IMAGES.LENCHANT,
    title: "Lenchant | Sereni",
    address: "Tuyaertsstraat 69-67 - 2850 Boom",
    hubMail: "zuid.antwerpen@sereni.be",
    email: "lenchant@sereni.be",
    website: "lenchant.sereni.be",
    cluster: "HUB Zuid Antwerpen",
    contactNo: "03 844 34 35",
    postalCode: "2850",
    url: "https://www.lenchantuitvaart.be/nl/",
    logo: LOGOS.LENCHANT
  },
  {
    id: 9,
    lat: 51.042,
    lng: 4.32686,
    src: IMAGES.STEVENS_LENCHANT,
    title: "Stevens & Lenchant | Sereni",
    address: "Pastoor Somerslaan 24 - 2870 Breendonk",
    hubMail: "zuid.antwerpen@sereni.be",
    email: "stevenslenchant@sereni.be",
    website: "stevenslenchant.sereni.be",
    cluster: "HUB Zuid Antwerpen",
    contactNo: "03 886 72 73",
    postalCode: "2870",
    url: "https://www.lenchantuitvaart.be",
    logo: LOGOS.STEVENS_LENCHANT
  },
  {
    id: 10,
    lat: 51.22034,
    lng: 4.38197,
    src: IMAGES.VAN_BOGAERT,
    title: "Van Bogaert | Sereni",
    address: "Waterhoenlaan / Blancefloerlaan",
    hubMail: "lo.antwerpen@sereni.be",
    email: "vanbogaert@sereni.be",
    website: "vanbogaert.sereni.be",
    cluster: "HUB LO Antwerpen",
    contactNo: "03 219 04 04",
    postalCode: "2050",
    url: "https://www.vanbogaertuitvaart.be/nl/",
    logo: LOGOS.VAN_BOGAERT
  },
  {
    id: 11,
    lat: 51.21851,
    lng: 4.32681,
    src: IMAGES.VAN_BOGAERT,
    title: "Van Bogaert | Sereni",
    address: "Statiestraat 15 - 2070 Zwijndrecht",
    hubMail: "lo.antwerpen@sereni.be",
    email: "vanbogaert@sereni.be",
    website: "vanbogaert.sereni.be",
    cluster: "HUB LO Antwerpen",
    contactNo: "03 219 04 04",
    postalCode: "2070",
    url: "https://www.vanbogaertuitvaart.be/nl/",
    logo: LOGOS.VAN_BOGAERT
  },
  {
    id: 12,
    lat: 51.22926,
    lng: 4.35633,
    src: IMAGES.VAN_BOGAERT,
    title: "Van Bogaert | Sereni",
    address: "Katwilgweg 7 - 2050 Antwerpen",
    hubMail: "lo.antwerpen@sereni.be",
    email: "vanbogaert@sereni.be",
    website: "vanbogaert.sereni.be",
    cluster: "HUB LO Antwerpen",
    contactNo: "03 219 04 04",
    postalCode: "2050",
    url: "https://www.vanbogaertuitvaart.be/nl/",
    logo: LOGOS.VAN_BOGAERT
  },
  {
    id: 13,
    lat: 51.16534,
    lng: 4.14069,
    src: IMAGES.VAN_DAEL,
    title: "Van Dael | Sereni",
    address: "Grote Markt 64 - 9100 Sint-Niklaas",
    hubMail: "lo.antwerpen@sereni.be",
    email: "vandael@sereni.be",
    website: "vandael.sereni.be",
    cluster: "HUB LO Antwerpen",
    contactNo: "03 777 00 16",
    postalCode: "9100",
    url: "https://www.vandaeluitvaart.be/nl/"
  },
  {
    id: 14,
    lat: 51.2161,
    lng: 4.25114,
    src: IMAGES.MELIS,
    title: "Melis | Sereni",
    address: "Yzerhand 49 - 9120 Beveren",
    hubMail: "lo.antwerpen@sereni.be",
    email: "melis@sereni.be",
    website: "melis.sereni.be",
    cluster: "HUB LO Antwerpen",
    contactNo: "03 775 19 90",
    postalCode: "9120",
    url: "https://www.melisuitvaart.be/nl/",
    logo: LOGOS.MELIS
  },
  {
    id: 15,
    lat: 51.19857,
    lng: 3.81021,
    src: IMAGES.VERHOEVEN,
    title: "Verhoeven | Sereni",
    address: "Oostkade 28b - 9060 Zelzate",
    hubMail: "gent@sereni.be",
    email: "verhoeven@sereni.be",
    website: "verhoeven.sereni.be",
    cluster: "HUB Gent",
    contactNo: "09 345 84 86",
    postalCode: "9060",
    url: "https://www.verhoevenuitvaart.be",
    logo: LOGOS.VERHOEVEN
  },
  {
    id: 16,
    lat: 51.04273,
    lng: 3.79574,
    src: "",
    title: "Brossé | Sereni",
    address: "Meersstraat 170 - 9070 Destelbergen",
    hubMail: "gent@sereni.be",
    email: "brosse@sereni.be",
    website: "brosse.sereni.be",
    cluster: "HUB Gent",
    contactNo: "09 230 37 38",
    postalCode: "9070",
    url: "https://www.brosseuitvaart.be",
    logo: LOGOS.BROSSE,
  },
  {
    id: 17,
    lat: 51.10989,
    lng: 3.70694,
    src: "",
    title: "De Piramide",
    address: "Hoeksken 17 - 9940 Evergem",
    hubMail: "gent@sereni.be",
    email: "depiramide@sereni.be",
    website: "depiramide.sereni.be",
    cluster: "HUB Gent",
    contactNo: "09 227 82 72",
    postalCode: "9940",
    url: "https://www.depiramideuitvaart.be",
    logo: LOGOS.DE_PIRAMIDE_ZWART,
  },
  {
    id: 18,
    lat: 50.99492,
    lng: 4.5538,
    src: "",
    title: "GVC | Sereni",
    address: "Ravesteinstraat 25 - 3191 Hever",
    hubMail: "mechelen@sereni.be",
    email: "gvc@sereni.be",
    website: "gvc.sereni.be",
    cluster: "HUB Mechelen",
    contactNo: "015 51 10 19",
    postalCode: "3191",
    url: "https://www.gvcuitvaart.be",
  },
  {
    id: 19,
    lat: 50.98168,
    lng: 4.57247,
    src: "",
    title: "GVC | Sereni",
    address: "Dorpplaats 4 - 3190 Boortmeerbeek",
    hubMail: "mechelen@sereni.be",
    email: "gvc@sereni.be",
    website: "gvc.sereni.be",
    cluster: "HUB Mechelen",
    contactNo: "015 51 22 95",
    postalCode: "3190",
    url: "https://www.gvcuitvaart.be/nl/",
  },
  {
    id: 20,
    lat: 50.97348,
    lng: 4.65136,
    src: "",
    title: "Feyaerts | Sereni",
    address: "Werchtersesteenweg 124 - 3150 Haacht",
    hubMail: "feyaerts@sereni.be",
    email: "feyaerts@sereni.be",
    website: "feyaerts.sereni.be",
    cluster: "HUB Mechelen",
    contactNo: "016 60 01 34",
    postalCode: "3150",
    url: "https://www.feyaertsuitvaart.be/nl/",
    logo: LOGOS.FEYAERTS,
  },
  {
    id: 21,
    lat: 50.99604,
    lng: 4.53046,
    src: "",
    title: "De Kapel | Sereni",
    address: "Leuvensesteenweg 769 - 2810 Mechelen",
    hubMail: "dekapel@sereni.be",
    email: "mechelen@sereni.be",
    website: "dekapel.sereni.be",
    cluster: "HUB Mechelen",
    contactNo: "015 76 71 00",
    postalCode: "2810",
    url: "https://www.troostattentie.be/wp-signup.php?new=www.dekapeluitvaart.be",
  },
  {
    id: 22,
    lat: 50.8577,
    lng: 4.31768,
    src: "",
    title: "Forrier | Sereni",
    address: "Begijnenstraat 18 - 1080 Brussel",
    hubMail: "brussel@sereni.be",
    email: "forrier@sereni.be",
    website: "forrier.sereni.be",
    cluster: " HUB Brussel",
    contactNo: "02 414 76 30",
    postalCode: "1080",
    url: "https://www.forrieruitvaart.be",
    logo: LOGOS.FORRIER,
  },
  {
    id: 23,
    lat: 50.86694,
    lng: 4.37401,
    src: "",
    title: " Mahy | Sereni",
    address: "Verweestraat 4 - 1030 Schaarbeek",
    hubMail: "brussel@sereni.be",
    email: "mahy@sereni.be",
    website: "mahy.sereni.be",
    cluster: " HUB Brussel",
    contactNo: "02 215 27 61",
    postalCode: "1030",
    url: "https://www.funeraillesmahy.be",
    logo: LOGOS.MAHY,
  },
  {
    id: 24,
    lat: 50.84355,
    lng: 4.41137,
    src: "",
    title: "Lits | Sereni",
    address: "Georges Henrilaan 303 - 1200 Sint-Lambrechts-Woluwe",
    hubMail: "brussel@sereni.be",
    email: "lits.sereni.be",
    website: "lits.sereni.be",
    cluster: "HUB Brussel",
    contactNo: "02 733 73 81",
    postalCode: "1200",
    url: "https://www.funerailleslits.be",
    logo: LOGOS.LITS,
  },
  {
    id: 25,
    lat: 50.80939,
    lng: 4.43608,
    src: "",
    title: "Popelier | Sereni",
    address: "J. Van Horenbeecklaan 17 - 1160 Oudergem",
    hubMail: "brussel@sereni.be",
    email: "popelier@sereni.be",
    website: "popelier.sereni.be",
    cluster: "HUB Brussel",
    contactNo: "02 672 43 88",
    postalCode: "1160",
    url: "https://www.sereni.be/vestigingen/popelier/",
    logo: LOGOS.POPELIER,
  },
  {
    id: 26,
    lat: 50.82337,
    lng: 4.40168,
    src: "",
    title: "Ergo & Poncelet",
    address: "Waversesteenweg 1141 - 1160 Oudergem",
    hubMail: "brussel@sereni.be",
    email: "ergo.poncelet@sereni.be",
    website: "ergo.poncelet@sereni.be",
    cluster: "HUB Brussel",
    contactNo: "02 648 75 06 - 02 660 26 48",
    postalCode: "1160",
    url: "https://www.funeraillesergoponcelet.be/",
    logo: LOGOS.ERGOPONCELET,
  },
  {
    id: 27,
    lat: 50.89435,
    lng: 4.3824,
    src: "",
    title: "De Hertogh | Sereni",
    address: "Heembeeksestraat 280 - 1120 Brussel",
    hubMail: "brussel@sereni.be",
    email: "dehertogh@sereni.be",
    website: "dehertogh.sereni.be",
    cluster: "HUB Brussel",
    contactNo: "02 262 07 60",
    postalCode: "1120",
    url: "https://www.funeraillesdehertogh.be/",
    logo: LOGOS.DEHERTOGH,
  },
  {
    id: 28,
    lat: 50.95438,
    lng: 3.29301,
    src: "",
    title: "Sabbe | Sereni",
    address: "Tieltstraat 51, 8760 Meulebeke",
    hubMail: "sabbeuitvaart.be",
    email: "sabbe@sereni.be",
    website: "sabbeuitvaart.be",
    cluster: "HUB Mandel-Leie",
    contactNo: "051 48 89 52",
    postalCode: "8760",
    url: "https://www.sabbeuitvaart.be/",
    logo: LOGOS.SABBE_ZWART,
  },
  {
    id: 29,
    lat: 50.91883,
    lng: 3.20593,
    src: "",
    title: "Snoeck | Sereni",
    address: "Bellevuestraat 24, 8870 Izegem",
    hubMail: "mandel.leie@sereni.be",
    email: "snoeckuitvaart.be",
    website: "snoeckuitvaart.be",
    cluster: "HUB Mandel-Leie",
    contactNo: "051 30 09 40",
    postalCode: "8870",
    url: "https://www.snoeck-izegem.be",
    logo: LOGOS.SNOECK_ZWART,
  },
  {
    id: 30,
    lat: 50.8144,
    lng: 3.25249,
    src: "",
    title: "D’haene | Sereni",
    address: "Pottelberg 27 - 8500 Kortrijk",
    hubMail: "mandel.leie@sereni.be",
    email: "dhaene@sereni.be .be",
    website: "dhaene.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 21 65 64",
    postalCode: "8500",
    url: "https://www.dhaeneuitvaart.be/nl/",
    logo: LOGOS.DHAENE,
  },
  {
    id: 31,
    lat: 50.79811,
    lng: 3.1308,
    src: "",
    title: "Pollie | Sereni",
    address: "Kortrijkstraat 207 - 8930 Menen",
    hubMail: "kortrijk@sereni.be",
    email: "pollie@sereni.be",
    website: "pollie.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 51 19 32",
    postalCode: "8930",
    url: "https://www.pollieuitvaart.be/nl/",
    logo: LOGOS.POLLIE,
  },
  {
    id: 32,
    lat: 50.84141,
    lng: 3.22958,
    src: "",
    title: "Debonné | Sereni",
    address: "Peperstraat 64 - 8501 Kortrijk-Heule",
    hubMail: "kortrijk@sereni.be",
    email: "debonne@sereni.be",
    website: "debonne.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 35 15 09",
    postalCode: "8510",
    url: "https://www.debonneuitvaart.be/nl/",
    logo: LOGOS.DEBONNE,
  },
  {
    id: 33,
    lat: 50.83164,
    lng: 3.30226,
    src: "",
    title: "Deseyne | Sereni",
    address: "Politieke Gevangenenstraat 126 - 8530 Harelbeke",
    hubMail: "kortrijk@sereni.be",
    email: "deseyne@sereni.be",
    website: "deseyne.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 20 40 25",
    postalCode: "8530",
    url: "https://www.deseyneuitvaart.be/nl/",
  },
  {
    id: 34,
    lat: 50.82702,
    lng: 3.22328,
    src: IMAGES.RIGOLE,
    title: "Rigole | Sereni",
    address: "Rijmsnoerstraat 72 - 8501 Kortrijk-Bissegem",
    hubMail: "kortrijk@sereni.be",
    email: "rigole@sereni.be",
    website: "rigole.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 35 42 53",
    postalCode: "8501",
    url: "https://www.rigoleuitvaart.be/nl/",
    logo: LOGOS.RIGOLE
  },
  {
    id: 35,
    lat: 50.83795,
    lng: 3.26331,
    src: IMAGES.BLOYART,
    title: "Bloyart | Sereni",
    address: "Roterijstraat 76 - 8500 Kortrijk",
    hubMail: "kortrijk@sereni.be",
    email: "bloyart@sereni.be",
    website: "bloyart.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 35 01 59",
    postalCode: "8500",
    url: "https://www.bloyartuitvaart.be/nl/",
    logo: LOGOS.BLOYART
  },
  {
    id: 36,
    lat: 50.77338,
    lng: 3.30859,
    src: IMAGES.PLANCKAERT,
    title: "Planckaert | Sereni",
    address: "Doornikserijksweg 193 - 8510 Kortrijk-Bellegem",
    hubMail: "kortrijk@sereni.be",
    email: "planckaert@sereni.be",
    website: "planckaert.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 22 29 53",
    postalCode: "8510",
    url: "https://www.planckaertuitvaart.be/nl/",
    logo: LOGOS.PLANCKAERT
  },
  {
    id: 37,
    lat: 50.8099,
    lng: 3.23101,
    src: IMAGES.BLOYART,
    title: "Leiekant - UItvaartAula",
    address: "Rekkemsestraat 83 - 8510 Kortrijk-Marke",
    hubMail: "kortrijk@sereni.be",
    email: "leiekant@sereni.be",
    website: "planckaert.sereni.be",
    cluster: "HUB Kortrijk",
    contactNo: "056 92 23 23",
    postalCode: "8510",
    url: "https://leiekant.be/"
  },
  {
    id: 38,
    lat: 51.13338,
    lng: 3.85826,
    src: IMAGES.ALLEMEERSCH,
    title: "Allemeersch | Sereni",
    address: "Bruggestraat 112 - 8480 Eernegem",
    hubMail: "brugge.oostende.kortemark@sereni.be",
    email: "allemeersch@sereni.be",
    website: "allemeersch.sereni.be",
    cluster: "HUB Brugge/Oostende/Kortemark",
    contactNo: "059 29 95 30",
    postalCode: "8480",
    url: "https://www.allemeerschuitvaart.be/nl/",
    logo: LOGOS.ALLEMEERSCH
  },
  {
    id: 39,
    lat: 51.1193,
    lng: 3.08974,
    src: IMAGES.ALLEMEERSCH,
    title: "Allemeersch | Sereni",
    address: "Steenstraat 1 - 8211 Aartrijke",
    hubMail: "brugge.oostende.kortemark@sereni.be",
    email: "allemeersch@sereni.be",
    website: "allemeersch.sereni.be",
    cluster: "HUB Brugge/Oostende/Kortemark",
    contactNo: "050 20 10 26",
    postalCode: "8211",
    url: "https://www.allemeerschuitvaart.be/nl/",
    logo: LOGOS.ALLEMEERSCH
  },
  {
    id: 40,
    lat: 51.02462,
    lng: 3.04072,
    src: IMAGES.LOGGHE,
    title: "Logghe I Sereni",
    address:
      "Amersveldestraat 10 - 8610 Kortemark EN Esenstraat 64a - 8610 Zarren",
    hubMail: "brugge.oostende.kortemark@sereni.be",
    email: "logghe@sereni.be",
    website: "logghe.sereni.be",
    cluster: "HUB Brugge/Oostende/Kortemark",
    contactNo: "051 57 03 03",
    postalCode: "8610",
    url: "https://www.loggheuitvaart.be/nl/",
    logo: LOGOS.LOGGHE
  },
  {
    id: 41,
    lat: 51.099245,
    lng: 3.02947,
    src: IMAGES.LOGGHE,
    title: "Logghe ICHTEGEM",
    address: "Engelstraat 214 - 8480 Ichtegem",
    hubMail: "brugge.oostende.kortemark@sereni.be",
    email: "logghe@sereni.be",
    website: "logghe.sereni.be",
    cluster: "HUB Brugge/Oostende/Kortemark",
    contactNo: "051 58 38 04",
    postalCode: "8480",
    url: "https://www.loggheuitvaart.be/nl/",
    logo: LOGOS.LOGGHE
  },
  {
    id: 42,
    lat: 51.21858,
    lng: 2.90768,
    src: IMAGES.SERVATY,
    title: "Servaty | Sereni",
    address: "Torhoutsesteenweg 212 - 8400 Oostende",
    hubMail: "brugge.oostende.kortemark@sereni.be",
    email: "servaty@sereni.be",
    website: "servaty.sereni.be",
    cluster: "HUB Brugge/Oostende/Kortemark",
    contactNo: "059 50 25 43",
    postalCode: "8400",
    url: "https://www.servatyuitvaart.be/nl/",
    logo: LOGOS.SERVATY
  },
  {
    id: 43,
    lat: 51.15655,
    lng: 3.19638,
    src: IMAGES.TIMMERMAN,
    title: "Timmerman | Sereni",
    address: "Steenbrugsestraat 3 - 8210 Loppem",
    hubMail: "brugge.oostende.kortemark@sereni.be",
    email: "timmerman@sereni.be",
    website: "timmeran.sereni.be",
    cluster: "HUB Brugge/Oostende/Kortemark",
    contactNo: "050 82 40 48",
    postalCode: "8210",
    url: "https://www.timmermanuitvaart.be/",
    logo: LOGOS.TIMMERMAN_ZWART
  },
  {
    id: 44,
    lat: 50.92424,
    lng: 4.00392,
    src: IMAGES.DERYCKE,
    title: "De Rycke | Sereni",
    address: "Maaldreef 11 - 9320 Nieuwerkerken",
    hubMail: "aalst@sereni.be",
    email: "derycke@sereni.be",
    website: "derycke.sereni.be",
    cluster: "Sereni HUB Aalst",
    contactNo: "053 70 05 00",
    postalCode: "9320",
    url: "https://www.deryckeuitvaart.be/nl/",
    logo: LOGOS.DERYCKE
  },
  {
    id: 45,
    lat: 51.02905,
    lng: 4.10264,
    src: IMAGES.BOGAERT,
    title: "Bogaert | Sereni",
    address: "Lindanusstraat 19 - 9200 Dendermonde",
    hubMail: "aalst@sereni.be",
    email: "bogaert@sereni.be",
    website: "bogaert.sereni.be",
    cluster: "Sereni HUB Aalst",
    contactNo: "052 21 30 25",
    postalCode: "9200",
    url: "https://www.bogaertuitvaart.be/nl/",
    logo: LOGOS.BOGAERT_ZWART
  },
  {
    id: 46,
    lat: 50.76239,
    lng: 4.27637,
    src: IMAGES.VANDERLINDEN,
    title: "Vanderlinden | Sereni",
    address: "Dworpsestraat 106 - 1651 Lot/Ninoofsesteenweg 79 - 1500 Halle",
    hubMail: "aalst@sereni.be",
    email: "vanderlinden@sereni.be",
    website: "vanderlinden.sereni.be",
    cluster: "Sereni HUB Aalst",
    contactNo: "02 331 01 82",
    postalCode: "1500",
    url: "https://www.vanderlindenuitvaart.be/nl/"
  },
  {
    id: 47,
    lat: 50.89305,
    lng: 4.07251,
    src: IMAGES.DEPOORTER,
    title: "Depoorter | Sereni",
    address: "Stationstraat 34 - 9470 Denderleeuw",
    hubMail: "aalst@sereni.be",
    email: "depoorter@sereni.be",
    website: "depoorter.sereni.be",
    cluster: "Sereni HUB Aalst",
    contactNo: "053 66 62 43",
    postalCode: "9470",
    url: "https://www.depoorteruitvaart.be/",
    logo: LOGOS.DEPOORTER_ZWART
  },
  {
    id: 48,
    lat: 50.90774,
    lng: 5.42529,
    src: IMAGES.REWEQHS,
    title: "Reweghs | Diepenbeek",
    address: "Wijkstraat 25 - 3590 Diepenbeek",
    hubMail: "hasselt@sereni.be",
    email: "reweghs.diepenbeek@sereni.be",
    website: "reweghs.sereni.be",
    cluster: "HUB Hasselt",
    contactNo: "011 32 30 58",
    postalCode: "3690",
    url: "https://www.reweghsuitvaart.be/nl/",
    logo: LOGOS.REWEQHS_ZWART
  },
  {
    id: 49,
    lat: 50.99292,
    lng: 4.84359,
    src: IMAGES.REWEQHS,
    title: "Reweghs | Aarschot",
    address: "Langdorpsesteenweg 10 - 3200 Aarschot",
    hubMail: "hasselt@sereni.be",
    email: "reweghs.aarschot@sereni.be",
    website: "reweghs.sereni.be",
    cluster: "HUB Hasselt",
    contactNo: "016 56 93 90",
    postalCode: "3200",
    url: "https://www.reweghsuitvaart.be/nl/",
    logo: LOGOS.REWEQHS_ZWART
  },
  {
    id: 50,
    lat: 50.98801,
    lng: 5.19295,
    src: IMAGES.REWEQHS,
    title: "Reweghs | Lummen",
    address: "Huzarenstraat 7A - 3560 Lummen",
    hubMail: "hasselt@sereni.be",
    email: "reweghs.lummen@sereni.be",
    website: "reweghs.sereni.be",
    cluster: "HUB Hasselt",
    contactNo: "013 52 29 73",
    postalCode: "3560",
    url: "https://www.reweghsuitvaart.be/nl/",
    logo: LOGOS.REWEQHS_ZWART
  },
  {
    id: 51,
    lat: 50.94768,
    lng: 4.90153,
    src: IMAGES.REWEQHS,
    title: "Reweghs | Tielt-Winge",
    address: "Rillaarseweg 103 - 3390 Tielt-Winge",
    hubMail: "hasselt@sereni.be",
    email: "reweghs.tielt-winge@sereni.be",
    website: "reweghs.sereni.be",
    cluster: "HUB Hasselt",
    contactNo: "016 50 11 16 ",
    postalCode: "3390",
    url: "https://www.reweghsuitvaart.be/nl/",
    logo: LOGOS.REWEQHS_ZWART
  },
  {
    id: 52,
    lat: 50.99325,
    lng: 5.37075,
    src: IMAGES.REWEQHS,
    title: "Reweghs | Zonhoven",
    address: " Grote Hemmenweg 14 - 3520 Zonhoven",
    hubMail: "hasselt@sereni.be",
    email: "reweghs.zonhoven@sereni.be",
    website: "reweghs.sereni.be",
    cluster: "HUB Hasselt",
    contactNo: "011 82 14 11",
    postalCode: "3520",
    url: "https://www.reweghsuitvaart.be/nl/",
    logo: LOGOS.REWEQHS_ZWART
  },
  {
    id: 53,
    lat: 50.85364,
    lng: 5.49498,
    src: IMAGES.HAYE,
    title: "Hayen | Sereni ",
    address: "Bilzersteenweg 30/31 - 3730 Hoesselt",
    hubMail: "hasselt@sereni.be",
    email: "hayen@sereni.be",
    website: "hayen.sereni.be",
    cluster: "HUB Hasselt",
    contactNo: "089 41 10 78",
    postalCode: "3730",
    url: "https://www.hayenuitvaart.be/",
    logo: LOGOS.HAYEN_ZWART
  },
  {
    id: 54,
    lat: 50.69514,
    lng: 4.04466,
    src: IMAGES.LUCAS,
    title: "Lucas | Sereni",
    address: "Place du Vieux Marché 50 - 7850 Enghien",
    hubMail: "bes@sereni.be",
    email: "lucas@sereni.be",
    website: "lucas.sereni.be",
    cluster: "HUB BES",
    contactNo: "02 395 33 85",
    postalCode: "7850",
    url: "https://www.funerailleslucas.be/fr/",
    logo: LOGOS.LUCAS
  },
  {
    id: 55,
    lat: 50.61042,
    lng: 4.13606,
    src: IMAGES.BRAINOISES,
    title: "Funérailles Brainoises | Sereni",
    address: "Grand Place 8 - 7090 Braine-le-Comte",
    hubMail: "bes@sereni.be",
    email: "brainoises@sereni.be",
    website: "brainoises.sereni.be",
    cluster: "HUB BES",
    contactNo: "067 55 32 10",
    postalCode: "7090",
    url: "https://www.funeraillesbrainoises.be/fr/",
    logo: LOGOS.FUNERAILLESBRAINOISES
  },
  {
    id: 56,
    lat: 50.54316,
    lng: 4.09293,
    src: IMAGES.LECLERCQ,
    title: "Leclercq & Deliener | Sereni",
    address: "Chaussée du Roeulx 519 - 7062 Soignies",
    hubMail: "bes@sereni.be",
    email: "leclercq@sereni.be",
    website: "leclercq.sereni.be",
    cluster: "HUB BES",
    contactNo: "067 33 23 15",
    postalCode: "7062",
    url: "https://www.funeraillesleclercq.be/fr/",
    logo: LOGOS.LECLERCQDELIENER
  },
  {
    id: 57,
    lat: 50.59608,
    lng: 3.47898,
    src: IMAGES.DESABLENS,
    title: "Desablens | Sereni",
    address: "Grand’Route 250 - 7530 Gaurain Ramecroix",
    hubMail: "tournai@sereni.be",
    email: "desablens@sereni.be",
    website: "desablens.sereni.be",
    cluster: "HUB Tournai",
    contactNo: "069 54 64 22",
    postalCode: "7530",
    url: "https://www.funeraillesdesablens.be/fr/",
    logo: LOGOS.DESABLENS
  },
  {
    id: 58,
    lat: 50.59608,
    lng: 3.47898,
    src: IMAGES.DELVAL,
    title: "Delval | Sereni",
    address: "Chaussée d'Audenarde 2 - 7500 Tournai",
    hubMail: "tournai@sereni.be",
    email: "delval@sereni.be",
    website: "delval.sereni.be",
    cluster: "HUB Tournai",
    contactNo: "069 84 68 18",
    postalCode: "7500",
    url: "https://www.funeraillesdelval.be/fr/",
    logo: LOGOS.DELVAL_ZWART
  },
  {
    id: 59,
    lat: 50.46188,
    lng: 5.10148,
    src: IMAGES.CALS,
    title: "Cals | Sereni",
    address: "350, Chaussée de Ciney - 5300 Coutisse",
    hubMail: "namur@sereni.be",
    email: "cals@sereni.be",
    website: "cals.sereni.be",
    cluster: "HUB Namur",
    contactNo: "085 61 14 39",
    postalCode: "5300",
    url: "https://www.funeraillescals.be/fr/"
  },
  {
    id: 60,
    lat: 50.53739,
    lng: 4.91419,
    src: IMAGES.JACQUEMIN,
    title: "Jacquemin | Sereni",
    address: "44 rue Gaston Dancot - 5310 Eghezée",
    hubMail: "namur@sereni.be",
    email: "jacquemin@sereni.be",
    website: "jacquemin.sereni.be",
    cluster: "HUB Namur",
    contactNo: "081 51 13 66",
    postalCode: "5310",
    url: "https://www.funeraillesjacquemin.be/",
    logo: LOGOS.JACQUEMIN_ZWART
  },
  {
    id: 61,
    lat: 50.53739,
    lng: 4.91419,
    src: IMAGES.BINCHOISES,
    title: "Binchoises | Sereni",
    address: "60, rue de la Samme - 7130 Binche",
    hubMail: "centre.sud@sereni.be",
    email: "binchoises@sereni.be",
    website: "binchoises.sereni.be",
    cluster: "HUB Centre Sud",
    contactNo: "064 33 74 12",
    postalCode: "7130",
    url: "https://www.funeraillesbinchoises.be/",
    logo: LOGOS.BINCHOISES_ZWART
  },
  {
    id: 62,
    lat: 50.40244,
    lng: 4.32907,
    src: IMAGES.FONTAINOISES,
    title: "Fontainoises | Sereni",
    address: "84, rue de Leernes - 6140 Fontaine-l'Evêque",
    hubMail: "centre.sud@sereni.be",
    email: "fontainoises@sereni.be",
    website: "fontainoises.sereni.be",
    cluster: "HUB Centre Sud",
    contactNo: "071 52 68 39",
    postalCode: "6140",
    url: "https://www.funeraillesfontainoises.be/",
    logo: LOGOS.FONTAINOISES_ZWART
  },
  {
    id: 63,
    lat: 50.237,
    lng: 4.23909,
    src: IMAGES.HONORE_DESCAMPS,
    title: "Honoré Descamps",
    address: "Avenue de l'esplanade 35 - Beaumont",
    hubMail: "centre.sud@sereni.be",
    email: "honore.descamps@sereni.be",
    website: "honoredescamps.sereni.be",
    cluster: "HUB Centre Sud",
    contactNo: "071 58 87 34",
    postalCode: "6500",
    url: "https://www.funerailleshonoredescamps.be",
    logo: LOGOS.HONORE_DESCAMPS_ZWART
  },
  {
    id: 64,
    lat: 51.205782,
    lng: 4.38779,
    src: IMAGES.MANAGMENT,
    title: "Management",
    address: "Jan Van Gentstraat 7 b4.2 - 2000 Antwerpen",
    hubMail: "",
    email: "sereni@sereni.be",
    website: "sereni.be",
    cluster: "Management",
    contactNo: "",
    postalCode: "2000",
    url: "https://www.sereni.be"
  },
];

export default listOfSereniLocations;
