import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { LuCookie } from "react-icons/lu";
import CookiesSettings from "./cookies-settings";

function Cookies() {
  const { t } = useTranslation();
  const [selectedSettingsType, setSelectedSettingsType] = useState<
    number | null
  >(null);

  useEffect(() => {
    const settingsType = localStorage.getItem("selectedSettingsType");
    if (settingsType) setSelectedSettingsType(JSON.parse(settingsType));
  }, []);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.acceptCookies")}
        declineButtonText={t("cookies.declineCookies")}
        enableDeclineButton
        cookieName="userConsent"
        onDecline={() => {
          localStorage.setItem("selectedSettingsType", "1"); // 1 - blocked settings type
          setSelectedSettingsType(1);
        }}
        expires={150}
        overlay
        containerClasses="flex flex-col md:flex-row bg-white px-4 py-3 font-matter"
        contentClasses="text-[#808080] text-xl xl:w-10/12" //!flex-none !sm:flex-[1_0_300px]
        buttonWrapperClasses="flex flex-col-reverse w-full md:2-4/12 xl:w-2/12 pb-4 sm:px-8 md:px-32 xl:px-0"
        buttonClasses="bg-white text-black border border-2 border-black my-0 mb-4"
        declineButtonClasses="bg-white text-black border border-2 border-black my-0"
      >
        {t("cookies.description")}
        <CookiesSettings
          selectedSettingsType={selectedSettingsType}
          setSelectedSettingsType={setSelectedSettingsType}
        />
      </CookieConsent>

      <CookiesSettings
        selectedSettingsType={selectedSettingsType}
        setSelectedSettingsType={setSelectedSettingsType}
        triggerComponent={
          <button className="bg-black rounded-full size-12 flex items-center justify-center fixed bottom-6 left-8 z-[99] cursor-pointer">
            <LuCookie color="white" size={26} />
          </button>
        }
      />
    </>
  );
}

export default Cookies;
