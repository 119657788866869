import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdBlock } from "react-icons/md";
import { IoMdCheckmark, IoMdSettings } from "react-icons/io";
import { FaRegChartBar } from "react-icons/fa";
import { TbSpeakerphone } from "react-icons/tb";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import Typography from "../ui/typography";
import { cn } from "../../utils";
import { LANGS } from "../../constants";

interface CookiesSettingsProps {
  selectedSettingsType: number | null;
  setSelectedSettingsType: (selectedSettingsType: number | null) => void;
  triggerComponent?: React.ReactNode;
}

function CookiesSettings({
  selectedSettingsType,
  setSelectedSettingsType,
  triggerComponent,
}: CookiesSettingsProps) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  let settingsTypes = [
    {
      id: 1,
      name: t("cookies.block"),
      icon: <MdBlock size={40} />,
      willDoItems: [],
      willDontItems: [],
    },
    {
      id: 2,
      name: t("cookies.essentials"),
      icon: <IoMdCheckmark size={40} />,
      willDoItems: [
        t("cookies.essentialsWillDo1"),
        t("cookies.essentialsWillDo2"),
        t("cookies.essentialsWillDo3"),
        t("cookies.essentialsWillDo4"),
        t("cookies.essentialsWillDo5"),
        t("cookies.essentialsWillDo6"),
      ],
      willDontItems: [
        t("cookies.essentialsWillDont1"),
        t("cookies.essentialsWillDont2"),
        t("cookies.essentialsWillDont3"),
        t("cookies.essentialsWillDont4"),
        t("cookies.essentialsWillDont5"),
        t("cookies.essentialsWillDont6"),
        t("cookies.essentialsWillDont7"),
        t("cookies.essentialsWillDont8"),
        t("cookies.essentialsWillDont9"),
      ],
    },
    {
      id: 3,
      name: t("cookies.functionality"),
      icon: <IoMdSettings size={40} />,
      willDoItems: [
        t("cookies.functionalityWillDo1"),
        t("cookies.functionalityWillDo2"),
        t("cookies.functionalityWillDo3"),
        t("cookies.functionalityWillDo4"),
        t("cookies.functionalityWillDo5"),
        t("cookies.functionalityWillDo6"),
        t("cookies.functionalityWillDo7"),
        t("cookies.functionalityWillDo8"),
      ],
      willDontItems: [
        t("cookies.functionalityWillDont1"),
        t("cookies.functionalityWillDont2"),
        t("cookies.functionalityWillDont3"),
        t("cookies.functionalityWillDont4"),
        t("cookies.functionalityWillDont5"),
        t("cookies.functionalityWillDont6"),
      ],
    },
    {
      id: 4,
      name: t("cookies.analytics"),
      icon: <FaRegChartBar size={40} />,
      willDoItems: [
        t("cookies.analyticsWillDo1"),
        t("cookies.analyticsWillDo2"),
        t("cookies.analyticsWillDo3"),
        t("cookies.analyticsWillDo4"),
        t("cookies.analyticsWillDo5"),
        t("cookies.analyticsWillDo6"),
        t("cookies.analyticsWillDo7"),
        t("cookies.analyticsWillDo8"),
        t("cookies.analyticsWillDo9"),
        t("cookies.analyticsWillDo10"),
        t("cookies.analyticsWillDo11"),
      ],
      willDontItems:
        i18n.language === LANGS.FR
          ? [t("cookies.analyticsWillDont1")]
          : [t("cookies.analyticsWillDont1"), t("cookies.analyticsWillDont2")],
    },
    {
      id: 5,
      name: t("cookies.advertisement"),
      icon: <TbSpeakerphone size={40} />,
      willDoItems: [
        t("cookies.advertisementWillDo1"),
        t("cookies.advertisementWillDo2"),
        t("cookies.advertisementWillDo3"),
        t("cookies.advertisementWillDo4"),
        t("cookies.advertisementWillDo5"),
        t("cookies.advertisementWillDo6"),
        t("cookies.advertisementWillDo7"),
        t("cookies.advertisementWillDo8"),
      ],
      willDontItems: [t("cookies.advertisementWillDont1")],
    },
  ];

  const onSettingsTypeClick = (id: number) => {
    setSelectedSettingsType(id);
    localStorage.setItem("selectedSettingsType", id.toString());
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {triggerComponent ?? (
          <button
            style={{
              marginLeft: "10px",
              background: "transparent",
              border: "none",

              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            {t("cookies.settings")}
          </button>
        )}
      </DialogTrigger>
      <DialogContent className="w-[98vw] max-w-[98vw] md:max-w-[80vw] px-2">
        <DialogHeader>
          <DialogTitle>{t("cookies.title")}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4 font-matter text-lg max-h-[65vh] overflow-y-scroll">
          <Typography>
          {t("cookies.caption")}
          </Typography>
          <Typography>
          {t("cookies.instruction")}
          </Typography>
          <ul className="flex px-0 cursor-pointer flex-wrap gap-4">
            {settingsTypes.map((settingsType, index) => (
              <li
                key={index}
                className={cn(
                  "flex flex-col items-center justify-center text-[#272621] bg-[#f2f2f280] w-full sm:w-[calc(50%-16px)] md:w-[calc(33%-16px)] lg:w-[calc(20%-20px)] py-9 rounded-sm",
                  ((selectedSettingsType === 1 && settingsType.id === 1) ||
                    (settingsType.id !== 1 &&
                      selectedSettingsType &&
                      settingsType.id <= selectedSettingsType)) &&
                    "bg-[#f2f2f2] border-2  border-[#ccc]"
                )}
                onClick={() => onSettingsTypeClick(settingsType.id)}
              >
                <div className="size-[80px] flex items-center justify-center bg-white rounded-full">
                  {settingsType.icon}
                </div>
                <Typography className="mt-[20px] font-medium">
                  {settingsType.name}
                </Typography>
              </li>
            ))}
          </ul>
          {selectedSettingsType !== 1 && (
            <div className="flex flex-col sm:flex-row px-2">
              <div className="flex flex-col w-full sm:w-1/2">
                <Typography className="font-medium text-xl">
                  {t("cookies.siteWillDo")}
                </Typography>
                <ul className="flex flex-col">
                  {selectedSettingsType &&
                    settingsTypes[selectedSettingsType - 1]?.willDoItems.map(
                      (doItem, index) => (
                        <li key={index} className="font-matter leading-10">
                          - {doItem}
                        </li>
                      )
                    )}
                </ul>
              </div>
              <div className="flex flex-col w-full sm:w-1/2">
                <Typography className="font-medium text-xl">
                  {t("cookies.siteWillDont")}
                </Typography>
                <ul className="flex flex-col">
                  {selectedSettingsType &&
                    settingsTypes[selectedSettingsType - 1]?.willDontItems.map(
                      (doItem, index) => (
                        <li key={index} className="font-matter leading-10">
                          - {doItem}
                        </li>
                      )
                    )}
                </ul>
              </div>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            onClick={() => setOpen(false)}
            variant="ghost"
            className="border border-black"
            type="submit"
          >
            {t("cookies.saveAndClose")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default CookiesSettings;
